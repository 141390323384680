export default {
  transition() {
    return {
      mode: 'out-in',
      // css: false,
      beforeEnter() {
        this.$i18n.finalizePendingLocaleChange();
      },
      enter(el, done) {
        const tl = this.$gsap.timeline({ onComplete: done });

        tl.fromTo(
          el,
          {
            // y: 100,
            opacity: 0,
          },
          {
            duration: 0.5,
            // y: 0,
            opacity: 1,
          },
        );
      },
      leave(el, done) {
        const tl = this.$gsap.timeline({ onComplete: done });
        tl.to(el, {
          duration: 0.5,
          // y: -50,
          opacity: 0,
        });
      },
    };
  },
};
