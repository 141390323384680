export default {
  methods: {
    formatHead(seomatic) {
      if (!seomatic) return {};

      // Convert the graphql JSON data to an object so we can work with it
      // Based on https://github.com/ben-rogerson/nuxt-seomatic-meta
      const {
        metaTitleContainer: {
          title: { title },
        },
        metaTagContainer,
        metaLinkContainer,
        metaScriptContainer,
        metaJsonLdContainer,
      } = Object.entries(seomatic).reduce((acc, [key, value]) => {
        if (key !== '__typename') acc[key] = JSON.parse(value);
        return acc;
      }, {});

      // Flatten metaTagContainer values into string
      const meta = metaTagContainer
        ? Object.values(metaTagContainer).reduce((flat, next) => {
            if (next.name === 'description') {
              // Override description tag with updated description
              next.hid = 'description';
            }
            return flat.concat(next);
          }, [])
        : null;

      // Flatten metaLinkContainer values into string
      const link = metaLinkContainer
        ? Object.values(metaLinkContainer).reduce((flat, next) => flat.concat(next), [])
        : null;

      // Convert script data to script tag contents
      const metaScripts = metaScriptContainer
        ? Object.values(metaScriptContainer).map(({ script }) => ({
            innerHTML: script,
          }))
        : [];

      // Convert JsonLd to script tag contents
      const jsonLd = metaJsonLdContainer
        ? Object.entries(metaJsonLdContainer).map((value) => ({
            type: 'application/ld+json',
            innerHTML: JSON.stringify(value[1]),
          }))
        : [];

      // Combine processed script data
      const script = [...metaScripts, ...jsonLd];

      const head = {
        htmlAttrs: {
          lang: this.$i18n.locale,
        },

        ...(title && { title }),
        ...(meta && { meta }),
        ...(link && { link }),
        script,
        __dangerouslyDisableSanitizers: ['script'],
      };

      return head;
    },
  },
};
