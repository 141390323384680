import seomaticMixin from '@/mixins/seomatic';
import transitionsMixin from '@/mixins/transitions';

export default {
  mixins: [seomaticMixin, transitionsMixin],
  inheritAttrs: false,
  head() {
    return this.formatHead(this.seomatic);
  },
  computed: {
    /**
     * By default: assume 'seomatic' is defined inside entry property.
     * Exception:product detail page. There, the prop is 'product'.
     */
    seomatic() {
      return this.entry?.seomatic || this.product?.seomatic;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.commit('scroll/unlockTracking');
    });
  },
};
